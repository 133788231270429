import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if(config.type != true || config.type ==undefined){
      var Authorization = sessionStorage.getItem("Authorization")
      config.headers.Authorization = Authorization
    }
    //eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImU3MWY0OWY3LWJlYTctNGVjMC04OWRhLTk5YzExZWJmYTE3YyJ9.3bKShJxCgo1WwiQnKXK68SxIhSoSxzGDNjrhkoD_vU9YF707ZF3Hr1wBJVE8WciYWTZsXDnG4W4ncIJGswDipw
    // config.headers.Authorization = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjRlNTE1ZjU2LWYyYzQtNGU1Yi04NDNiLTU1MDY3OTAzNjIwYyJ9.F9NbaRiILzznFVOXVqa_iSv2KB1b19ElkbVpcXNIx6Kvr0A63x12qAJXwVGPvYB4ypCXRA2jP2p6OgXlZXRA6g";
    // config.headers.Authorization = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI5MDE5ZWFlLTFhZDUtNGVjMS04NzY0LTAxNjM4MDI3N2I1NyJ9.bqQEospy2qWC5IXtWFaWnW10hbu8CbgJZe98mQq0wYs7fzstZAaQBbCWmxV3erMaPQ7u_Q5t61lT9KfIWRLkgQ";
    // config.headers.Authorization = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjU3YWFjZGNhLTY1NzAtNDc1Yy1hYWQ0LTdiNWY3MjIyNTUxNSJ9.xejJIyK4gztFBc1a4WJ3hYWe2rsRrtqhsQLE_8NjqOexc9S3ZBaoJcAZkmp6PTtPDoCA2Ph_3St91PQO6P6RWA";

    // config.headers.Authorization = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImYzYzJhNzdmLTFiMDItNDdjMC05MzQ5LTVmNjdhMGVmMWI0NyJ9.I39SH8krgg_IX3b8dnejPlrBlZUbdx1N97nAUFr5-1ebRrCYDzId-qxdhSq-PBQILAex9mfA7lgEDcXLOu0kaw"
    // config.headers.Authorization = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjE4ODJhNjUyLWM3MzctNGE4OC05ZTcxLTkwNWQwNDU2NWY1YyJ9.kcSUkesx_eYetmcZ12Jv39hxfdCDGavXM2jmYTwQCQeHRJe9eajxDDmldwop2uxR5nYEatjTVIvFzcviAa9PVw"
    //  config.headers.Authorization = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjdmZjQ0N2ZmLTNlNzMtNGEwMC1iNDlkLWM2ODljODlmMTYxYyJ9.aTJAB3wOYHbcPilrnW_okerKbAIUvoZcuZdCvNy0DaieRdbDdN72YOX_Shn1CFXm1DTSWMYM2zrW__djX8GUcg"
     // config.headers.Authorization = Authorization.Authorization
     // config.headers.Authorization = config.headers.cookies.Authorization
    // do something before request is sent
    //   if (store.getters.token) {
    //     // let each request carry token
    //     // ['X-Token'] is a custom headers key
    //     // please modify it according to the actual situation
    //     config.headers['Authorization'] = 10
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    const { code } = dataAxios
    // 根据 code 进行判断
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      return dataAxios
      // switch (code) {
      //   case 10000:
      //     // [ 示例 ] code === 0 代表没有错误
      //     return dataAxios
      //   case 'xxx':
      //     // [ 示例 ] 其它和后台约定的 code
      //     // errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`)
      //     break
      //   default:
      //     // 不是正确的 code
      //     // errorCreate(`${dataAxios.msg}: ${response.config.url}`)
      //     break
      // }
    }
  },
  error => {
    console.dir(error.response)
    // const status = get(error, 'response.status')
    // switch (status) {
    //   case 400: error.message = '请求错误'; break
    //   case 401: error.message = '未授权，请登录'; break
    //   case 403: error.message = '拒绝访问'; break
    //   case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
    //   case 408: error.message = '请求超时'; break
    //   case 500: error.message = `${error.response.data.message}`; break
    //   case 501: error.message = '服务未实现'; break
    //   case 502: error.message = '网关错误'; break
    //   case 503: error.message = '服务不可用'; break
    //   case 504: error.message = '网关超时'; break
    //   case 505: error.message = 'HTTP版本不受支持'; break
    //   default: break
    // }
    // Message.error(error.msg)
    return Promise.reject(error)
  }
)

export default service
