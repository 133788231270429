<template>
  <div class="home">
    <div class="header">
      <div class="bacc">
        <div class="header-top">
          <div class="logo" @click="handleLogo">
            <img src="../../assets/home/logo1.png" />
          </div>
          <ul class="menuBox">
            <li class="menu-item" v-for="item in menuList" :key="item.id" @click.stop="handleMenu(item)"
              @mouseover.stop="routerShow(item)" @mouseleave="handleMouseLeave(item)">
              {{ item.name }}
              <svg v-show="item.icon" style="margin-left: 5px" t="1715135267702" class="icon" viewBox="0 0 1024 1024"
                version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1451" width="16" height="16">
                <path
                  d="M512.726547 675.318646c-8.063653 0-15.790638-3.245927-21.435195-9.006118L231.175103 400.906809c-11.603269-11.837606-11.410887-30.840402 0.427742-42.442648 11.837606-11.601222 30.841426-11.410887 42.442648 0.427742l238.681054 243.534596L751.407602 358.891903c11.601222-11.839653 30.602995-12.033058 42.442648-0.427742 11.839653 11.603269 12.031011 30.605042 0.427742 42.442648L534.161742 666.312528C528.517185 672.072719 520.791224 675.318646 512.726547 675.318646z"
                  fill="#ffffff" p-id="1452"></path>
              </svg>
              <div v-show="item.IschildShow" class="childmenu">
                <div class="s-our-serive-content">
                  <ul class="s-our-serive-content-list">
                    <li class="s-our-serive-content-item" v-for="child in item.child" :key="child.id"
                      @click.stop="handleChildMenu(item, child)">
                      <div class="s-our-serive-content-item-img">
                        <img :src="child.imgSrc" alt="" />
                      </div>
                      <div style="width: 90%">
                        <p class="s-our-serive-content-item-content">{{ child.content }}</p>
                        <p class="s-our-serive-content-item-desc">{{ child.desc }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <div class="bookinspection" style="margin-right: 30px;">
              <p class="bookBtn" @click="handleShowComment">BOOK INSPECTION</p>
            </div>
          </ul>
        </div>
      </div>
      <div class="header-bottom">
        <p class="font" style="font-size: 70px; color: white;margin-bottom: 20px">The Leading</p>
        <p class="frist font" style="margin-bottom: 40px;">Home Inspection Company in Samui</p>
        <p class="second ">Friendly, prompt, and thorough home inspectors you can trust</p>
      </div>
    </div>
    <div class="intention">

      <p class="frist font"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block; "></span>Our Objective</p>
      <p class="sceoned" style="text-align: left; font-size: 18px; line-height: 40px;">
        The objective of Samui Home Service is to deliver
        exceptional services to condominium and residential building owners, ensuring
        their safety and comfort within their premises. We strive to act as a trusted
        butler, attending to their cleaning and repair needs, just as one would care for
        their own home.
      </p>
<!-- 
      <p @click="RouterLink" style="margin-top: 30px;"><img src="../../assets/home/certification.png"
          style="width: 100%; height: 100%; object-fit: cover" /></p> -->
    </div>
    <div class="offer-box">
      <p class="frist font">What We Offer</p>
      <p class="sconed font">
        <span>Better Service Starts Here.</span>
      </p>

      <!-- <li>
          <img @click="structuralRouter()" src="../../assets/home/图片1.png" alt="" />
          <p>Structural Inspection</p>
        </li>
        <li>
          <img @click="electricalRouter()" src="../../assets/home/图片1备份.png" alt="" />
          <p>Electrical Inspection</p>
        </li>
        <li>
          <img @click="plumbingRouter()" src="../../assets/home/图片1备份1.png" alt="" />
          <p>Plumbing and sanitation Inspection</p>
        </li> -->


      <div class="swiper" style="width:100%;margin:20px auto;height:450px">
        <!-- Using the slider component -->
        <slider ref="slider" :options="options">
          <!-- slideritem wrapped package with the components you need -->
          <slideritem class="swiper_item" v-for="(item, index) in someList" :key="index" :style="item.style">
            <img @click="router(item.router)" style="height: 100%; width: 100%;" :src="item.src" alt="">
            <p>{{ item.html }}</p>
          </slideritem>
          <!-- Customizable loading -->
          <div slot="loading">loading...</div>
        </slider>
      </div>

    </div>
    <div class="work-box">
      <p class="frist font"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block;"></span>How
        We Work</p>
      <p class="sceoned"><img src="../../assets/home/work.png" style="width: 100%; height: 100%; object-fit: cover" />
      </p>
    </div>

    <div class="products-box">
      <p class="frist font"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block;"></span>Our
        Portfolio</p>
      <p class="sceoned" style="text-align: left;margin-bottom: 50px;">
        Inspection on Thailand typical house
      </p>
      <img @click="portfolioRouter()" style="width: 100%; margin: px 0px;" src="../../assets/home/bginfo.jpg"
        alt="">
      <!-- <p class="more"><span class="moreBtn" @click="PortfolioRouter">MORE</span></p> -->
      <ul class="products-img-list">
        <li v-for="(item, index) in products" :key="index">
          <img :src="item.imageUrl" alt="" />
          <p>{{ item.title }}</p>
        </li>
      </ul>
      <p class="third font"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block; "></span>Samui Home Service Reviews</p>
      <p class="reviews"><span class="reviewsBtn font" @click="reviewsRouter">Read All Reviews</span></p>
      <ul class="reviews-img-list">
        <li>
          <div class="avtar-box">
            <p>
              <img style="
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  vertical-align: middle;
                " src="../../assets/reviews/矩形.jpg" alt="" /><span class="name">Grace</span>
            </p>
            <p><img src="../../assets/home/star.png" alt="" /></p>
          </div>
          <div class="reviews-desc">
            <p>
              The inspector's friendly and approachable demeanor made the inspection process a pleasant and informative
              experience.
            </p>

          </div>
        </li>
        <li>
          <div class="avtar-box">
            <p>
              <img style="
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  vertical-align: middle;
                " src="../../assets/reviews/矩形备份 10.jpg" alt="" /><span class="name">Avery</span>
            </p>
            <p><img src="../../assets/home/star.png" alt="" /></p>
          </div>
          <div class="reviews-desc">
            <p>The inspector's attention to detail and comprehensive approach have provided me with great peace of mind.
            </p>
          </div>
        </li>
        <li>
          <div class="avtar-box">
            <p>
              <img style="
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  vertical-align: middle;
                " src="../../assets/reviews/矩形备份 11.jpg" alt="" /><span class="name">Evelyn</span>
            </p>
            <p><img src="../../assets/home/star.png" alt="" /></p>
          </div>
          <div class="reviews-desc">
            <p>
              I'm confident that the inspector's assessment will protect my investment and ensure a sound purchase
              decision.
            </p>

          </div>
        </li>
      </ul>
    </div>
    <div class="insights-box">
      <p class="frist font"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block; "></span>Read
        the Latest Insights</p>
      <p class="second" style="text-align: left;">
        Explore trending topics to maintain and protect your home, your most valuable
        investment.
      </p>
      <p class="explore"><span class="exploreBtn font" @click="newsRouter">Explore All</span></p>
      <ul class="insights-box-list">
        <li @click="handlegoDetail(item)" v-for="(item, index) in news" :key="index">
          <div class="box-list-label">{{ item.label }}</div>
          <p class="insights-img-box">
            <img style="width: 100%; height: 100%" :src="item.coverUrl" alt="" />
          </p>
          <p class="insights-desc">{{ item.title }}</p>
          <p class="insights-more">Read More</p>
        </li>
      </ul>
    </div>
    <footerBottom />
    <commentDialog :show.sync="show" />

  </div>

</template>
<script>
import { slider, slideritem } from 'vue-concise-slider'

import { RouterLink } from "vue-router";
import footerBottom from "../../components/footer.vue";
import { getNewsList, getProtfolioList } from "@/api/contact";
import commentDialog from "./component/commentDialog.vue"
export default {
  components: {
    footerBottom,
    commentDialog,
    slider,
    slideritem
  },

  data() {
    return {

      someList: [
        {
          router: 'hood',
          src: require('../../assets/serivice/厨房检查.jpg'),
          html: 'Hood Inspection',
          style: {

            'width': '32%',
            'margin-right': '2%'
          }
        },
        {
          router: 'door',
          src: require('../../assets/serivice/门窗检查.jpg'),
          html: 'Door and Window Inspection',
          style: {
            'width': '32%',
            'margin-right': '2%'
          }
        },
        {
          router: 'electrical',
          src: require('../../assets/serivice/电气.jpg'),
          html: 'Electrical Inspection',
          style: {
            'width': '32%',
            'margin-right': '2%'
          }
        },
        {
          router: 'structural',
          src: require('../../assets/serivice/people.jpg'),
          html: 'Structural Inspection',
          style: {
            'width': '32%',
            'margin-right': '2%'
          }
        },
        {
          router: 'internet',
          src: require('../../assets/serivice/互联网和闭路电视检查.jpg'),
          html: 'Internet and CCTV Inspection',
          style: {
            'width': '32%',
            'margin-right': '2%'
          }
        },
        {
          router: 'roof',
          src: require('../../assets/serivice/屋顶检查.jpg'),
          html: 'Roof Inspection',
          style: {
            'width': '32%',
            'margin-right': '2%'
          }
        },
        {
          router: 'plumbing',
          src: require('../../assets/serivice/管道检查.jpg'),
          html: 'Plumbing Inspection',
          style: {
            'width': '32%',
            'margin-right': '2%'
          }
        },
        {
          router: 'air',
          src: require('../../assets/serivice/空调检查.jpg'),
          html: 'Air conditioning Inspection',
          style: {
            'width': '32%',
            'margin-right': '2%'
          }
        },
        {
          router: 'stair',
          src: require('../../assets/serivice/楼梯检查.jpg'),
          html: 'Stair Inspection',
          style: {
            'width': '32%',
            'margin-right': '2%'
          }
        }
      ],
      options: {
        currentPage: 0,
        tracking: false,
        thresholdDistance: 100,
        thresholdTime: 300,
        infinite: 1,
        autoplay: 3000,
        loop: true,
        loopedSlides: 3, // 设置循环的幻灯片数量为 3
        preventDocumentMove: true,

        preventRebound: false,
        slide() {
          this.$refs.slider.$emit('slidePre')
        },
        slideNext() {
          this.$refs.slider.$emit('slideNext')
        },


        slidesToScroll: 1,
        loop: true
      },


      show: false,
      products: [],
      news: [],
      menuList: [
        {
          id: "1",
          names: "index",
          name: "Who We Are",
          path: "/index",
        },
        {
          id: "2",
          names: "services",
          name: "Services",
          icon: true,
          IschildShow: false,
          path: "/serivices",
          child: [
            {
              id: "1",
              imgSrc: require("../../assets/serivice/icon_1.png"),
              content: "Hood Inspection",
              desc: "range hood, ventilation fans and cleanliness",
              path: "/serivices/hood",
            },
            {
              id: "2",
              imgSrc: require("../../assets/serivice/icon_2.png"),
              content: "Door and Window Inspection",
              desc: "automatic door system and digital door lock",
              path: "/serivices/door",
            },
            {
              id: "3",
              imgSrc: require("../../assets/serivice/icon_3.png"),
              content: "Electrical Inspection",
              desc: "electrical inspection interior and exterior",
              path: "/serivices/electrical",
            },
            {
              id: "4",
              imgSrc: require("../../assets/serivice/icon_4.png"),
              content: "Structural Inspection",
              desc: "foundation, structure and inside wall inspection ",
              path: "/serivices/structural",
            },
            {
              id: "5",
              imgSrc: require("../../assets/serivice/icon_4.png"),
              content: "Internet and CCTV Inspection",
              desc: "internet system and CCTV system",
              path: "/serivices/internet",
            },
            {
              id: "6",
              imgSrc: require("../../assets/serivice/icon_6.png"),
              content: "Roof Inspection",
              desc: "roof and rain gutters",
              path: "/serivices/roof",
            },
            {
              id: "7",
              imgSrc: require("../../assets/serivice/icon_7.png"),
              content: "Plumbing Inspection",
              desc:
                "water meters, water storage tanks, water pumps, sanitary ware, wastewater treatment tanks, grease traps, and drainage systems",
              path: "/serivices/plumbing",
            },
            {
              id: "8",
              imgSrc: require("../../assets/serivice/icon_8.png"),
              content: "Air conditioning Inspection",
              desc: "foundation, structure and inside wall inspection ",
              path: "/serivices/air",
            },
            {
              id: "9",
              imgSrc: require("../../assets/serivice/icon_9.png"),
              content: "Stair Inspection",
              desc: "stair for public area",
              path: "/serivices/stair",
            },
          ],
        },
        {
          id: "3",
          names: "portfolio",
          name: "Portfolio",
          path: "/portfolio",
        },
        {
          id: "4",
          names: "reviews",
          name: "Reviews",
          path: "/reviews",
        },
        {
          id: "5",
          names: "new",
          name: "News",
          path: "/new",
        },
      ],
    };
  },
  created() {
  },
  mounted() {
    const _this = this;


    // 产品图
    getProtfolioList(1, 8).then(res => {
      if (res.code === 200) {
        this.products = res.rows
      }
    })

    // 新闻
    getNewsList(1, 4).then(res => {
      if (res.code === 200) {
        this.news = res.rows;
        console.log(this.news, '');
      }
    })
  },

  methods: {
    router(item) {
      this.$router.push(`serivices/${item}`)
    },
    electricalRouter() {
      this.$router.push('serivices/electrical')

    },
    structuralRouter() {
      this.$router.push('serivices/structural')
    },
    plumbingRouter() {
      this.$router.push('serivices/plumbing')
    },
    portfolioRouter() {
      this.$router.push({ name: 'portfolio' })
    },

    handleShowComment() {
      this.show = true
    },
    newsRouter() {
      this.$router.push({ name: "new" })
    },
    reviewsRouter() {
      this.$router.push({ name: "reviews" })
    },
    PortfolioRouter() {
      this.$router.push({ name: "portfolio" })
    },
    handleLogo() {
      location.reload();
    },
    handlegoDetail(item) {
      this.$router.push({ name: 'newDetail', params: item })
    },
    handleMouseLeave(item) {
      if (item.id == 2) {
        item.IschildShow = false;
      }
    },
    routerShow(item) {
      if (item.id != 2) {
        item.IschildShow = false;
        // this.$router.push(
        //   item.path,
        //   () => { },
        //   () => { }
        // );
      } else {
        item.IschildShow = true;
      }
    },
    handleMenu(item) {
      if (item.id != 2) {
        item.IschildShow = false;
        this.$router.push(
          item.path,
          () => { },
          () => { }
        );
      } else {
        item.IschildShow = true;
      }
    },
    RouterLink() {
      this.$router.push({ name: 'certicication' })
    },
    handleChildMenu(item, child) {
      item.IschildShow = false;
      this.$router.push(
        child.path,
        () => { },
        () => { }
      );
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: 'MyCustomFont';
  src: url('../../assets/fonts/Avenir\ Next.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.slider-pagination {
  bottom: -100px !important;
}

.swiper_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-container {
  height: 500px;
}

/* .slider-pagination{
  margin-top: 50px;
} */
.swiper img {
  width: 100%;
  max-width: 380px;
  max-height: 400px;
  object-fit: cover;
  border-radius: 20px;
}

.swiper p {
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px
}

.font {
  font-family: 'MyCustomFont', sans-serif;
}

.swiper {
  background: '#7caabe';
  width: '23.5%';
  margin-right: '2%'
}

.header {

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 800px;
  background: #b7aea1;
  
  box-sizing: border-box;
  justify-content: space-between;
  background: url("../../assets/home/ban_gif.gif");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.header-top {
  display: flex;
  width: 100%;
  height: 1080px;
  justify-content: space-between;
  /* padding: 10px 20px 10px 20px; */
}
.bacc{
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  padding: 10px 20px 10px 20px;
  overflow: hidden;
}
.logo {
  cursor: pointer;
  width: 70px;
  height: 50px;
}

.logo img {
  width: 100%;
  height: 100%;
}

.menuBox {
  display: flex;
  width: 70%;
  justify-content: space-between;
  color: #fff;
  height: 50px;
  align-items: center;
  font-size: 16px;
}

.menuBox .menu-item {
  cursor: pointer;
  position: relative;
}

.bookBtn {
  width: 200px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  color: #007562;
  font-weight: 800;
  cursor: pointer;
  margin-left: 10px;
}

.header-bottom {
  position: absolute;
  top: 60%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.header-bottom .frist {
  font-size: 30px;
  text-align: center;
  color: #fff;
}

.header-bottom .second {
  font-size: 18px;
  text-align: center;
  color: #fff;
  opacity: 0.73;
  margin-top: 28px;
  border: 1px solid white;
  border-radius: 20px;

}

.intention {
  width: 100%;
  height: 300px;
  background: #fff;
  padding: 50px 166px 0px;
  box-sizing: border-box;
  /* margin-bottom: 50px; */
}

.intention .frist {
  color: #007562;
  text-align: center;
  font-size: 30px;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.intention .sceoned {
  text-align: center;
  font-size: 24px;
  margin-top: 30px;
}

.intention .third {
  color: #007562;
  text-align: center;
  font-size: 30px;
  margin-top: 100px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}



.offer-box {
  width: 100%;
  height: 730px;
  background: #007562;
  padding: 50px 90px 100px;
  box-sizing: border-box;
}

.third-box {
  display: flex;
  justify-content: space-between;
}

.offer-box .frist {
  font-size: 30px;
  text-align: center;
  color: #fff;
  font-weight: 100;
  display: flex;
  flex-direction: row;
  text-indent: 100px;
}

.offer-box .sconed {
  font-size: 30px;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  text-indent: 100px;
}

.offer-box .third {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.offer-box .third li {
  text-align: center;
  margin: 0 auto;
}

.offer-box .third li img {
  width: 100%;
  max-width: 380px;
  max-height: 400px;
  object-fit: cover;
  /*width: 530px;*/
  /*height: 640px;*/
}

.offer-box .third li p {
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 21px;
}

.work-box {
  padding: 50px 166px 30px;
  box-sizing: border-box;
}

.work-box .frist {
  font-size: 30px;
  color: #007562;
  text-align: center;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.work-box .sceoned {
  margin-top: 26px;
  text-align: center;
}

.products-box {
  padding: 0 167px 80px;
}

.products-box .frist {
  font-size: 30px;
  color: #007562;
  text-align: center;
  /* margin-top: 50px; */
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.products-box .sceoned {
  font-size: 18px;
  margin-top: 30px;
  text-align: center;
}

.products-box .more {
  display: flex;
  justify-content: end;
  width: 100%;
}

.products-box .moreBtn {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #007562;
  text-align: center;
  line-height: 30px;
  color: #007562;
  font-size: 16px;
  margin-right: 31px;
  margin-top: 10px;
}

.products-img-list {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.products-img-list li {
  width: 24.4%;
  margin-right: 9px;
  font-size: 18px;
}

.products-img-list li img {
  width: 100%;
}

.products-img-list li p {
  text-align: center;
  color: #333333;
  margin: 24px 0;
}

.products-box .third {
  margin-top: 50px;
  font-size: 30px;
  color: #007562;
  text-align: center;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.reviews {
  width: 100%;
  display: flex;
  justify-content: end;
}

.reviewsBtn {
  width: 190px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #007562;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: #007562;
  margin-top: 20px;
}

.reviews-img-list {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.reviews-img-list li {
  width: 33%;
  height: 304px;
  box-shadow: 1px 6px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px 32px 29px 34px;
  box-sizing: border-box;
}

.avtar-box {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.avtar-box .name {
  font-size: 20px;
  color: #007562;
  margin-left: 13px;
  vertical-align: middle;
}

.reviews-desc {
  /* margin: 20px 32px 29px 34px; */
  margin-top: 20px;
  padding: 10px 20px 20px;
  box-sizing: border-box;
  background: #f3f8f8;
  height: 180px;
  font-size: 16px;
}

.reviews-desc p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.reviews-desc .reviews-more {
  color: #007562;
  font-size: 14px;
}

.insights-box {
  width: 100%;
  /* height: 738px; */
  background: #f4f4f4;
  padding: 50px 166px 50px;
  box-sizing: border-box;
}

.insights-box .frist {
  text-align: center;
  font-size: 30px;
  color: #007562;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.insights-box .second {
  text-align: center;
  font-size: 18px;
  color: #333333;
  margin-top: 30px;
}

.insights-box .explore {
  display: flex;
  justify-content: end;
  width: 100%;
}

.insights-box .exploreBtn {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #007562;
  text-align: center;
  line-height: 30px;
  color: #007562;
  font-size: 16px;
  margin-top: 10px;
}

.insights-box-list {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.insights-box-list li {
  position: relative;
  width: 32%;
  height: 400px;
  background: #fff;
  border-radius: 10px;
}

/* .insights-box-list li img {
  height: 302px;
} */

.box-list-label {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #FFFFFF;
  padding: 4px;
  border-radius: 3px;
}

.insights-img-box {
  height: 260px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.insights-desc {
  margin-top: 17px;
  margin-left: 20px;
  font-size: 16px;
  height: 50px;
}

.insights-more {
  margin-top: 25px;
  margin-left: 20px;
  font-size: 16px;
  color: #007562;
  text-decoration: underline;
  cursor: pointer;
}

.childmenu {
  width: 738px;
  height: 460px;
  position: absolute;
  top: 20px;
  left: -300px;
  z-index: 999;
}

.s-our-serive-content {
  height: 430px;
  background: #f3f8f8;
  border-radius: 10px;
  border: 1px solid #007562;
  margin-bottom: 50px;
  padding: 52px 2px 50px 28px;
  box-sizing: border-box;
}

.s-our-serive-content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.s-our-serive-content-item {
  width: 48%;
  display: flex;
  margin-bottom: 20px;
  /* margin-bottom: 101px; */
}

.s-our-serive-content-item-img {
  width: 22px;
  height: 22px;
  background: #007562;
  margin-right: 10px;
  margin-top: 7px;
}

.s-our-serive-content-item-img img {
  width: 100%;
  height: 100%;
}

.s-our-serive-content-item-content {
  font-size: 16px !important;
  color: #000000;
}

.s-our-serive-content-item-desc {
  font-size: 12px;
  color: #333333;
  margin-top: 2px;
}
</style>
