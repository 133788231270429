<template>
  <div>
    <headerTop />
    <breadcrumb :breadList="breadList" />
    <div class="c-content">

      <p class="s-waterfall-title font" style="display: flex; flex-direction: row;margin-right: 20px;"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block; "></span>You're
        in Good Company</p>
      <ul class="reviews-img-list">
        <li class="reviews-img-list-item" v-for="item in data" :key="item.id">
          <div class="avtar-box">
            <p><img style="width:40px;height:40px;border-radius:50%;vertical-align: middle;" :src="item.avatar_url"
                alt="" />
            <p class="name">{{ item.name }}</p>
            </p>
            <p><img src="../../assets/home/star.png" alt="" /></p>
          </div>
          <div class="reviews-desc">
            <p>
              {{ item.content }}
            </p>

          </div>
        </li>
      </ul>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      style="text-align: center;" :current-page="tablePage.pageNum" :i18n="{
      prev: '',
      next: '',
      total: ''
    }" :page-size="tablePage.pageSize" layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <!-- <pagination /> -->
    <footerBottom />
    <commentDialog :show.sync="show" />
  </div>
</template>
<script>
import headerTop from "../../components/header.vue";
import breadcrumb from "../../components/breadcrumb.vue";
import pagination from "../../components/pagination.vue"
import footerBottom from "../../components/footer.vue";
import commentDialog from "./component/commentDialog.vue"
export default {
  components: {
    headerTop,
    breadcrumb,
    pagination,
    footerBottom,
    commentDialog
  },
  data() {
    return {
      customI18n: {
        // 自定义内置文字
        // 其他文本
        // 例如：
        page: '',
        // itemsPerPage: '每页',
        total: ``,
        jumpToPage: ''
      },
      total: 189,
      tablePage: {
        pageNum: 1,
        pageSize: 9,
      },
      breadList: ["Home", "Reviews"],
      list: [
        {
          "id": 0,
          "name": "Emma",
          "content": "The inspection was thorough and professional. I feel confident in the condition of my home.",
          "avatar_url": require("../../assets/reviews/矩形备份 287.jpg")
        },
        {
          "id": 1,
          "name": "Jacob",
          "content": "I'm impressed with the level of detail and expertise shown by the inspector. They left no stone unturned.",
          "avatar_url": require("../../assets/reviews/矩形备份 293.jpg")
        },
        {
          "id": 2,
          "name": "Ethan",
          "content": "As a first-time homebuyer, the inspection report provided valuable insight and peace of mind.",
          "avatar_url": require("../../assets/reviews/矩形备份 244.jpg")
        },
        {
          "id": 3,
          "name": "Sophia",
          "content": "The inspector's communication throughout the process was excellent. They explained everything clearly and answered all my questions.",
          "avatar_url": require("../../assets/reviews/矩形备份 64.jpg")
        },
        {
          "id": 4,
          "name": "Liam",
          "content": "I'm grateful for the inspector's diligence in identifying potential issues. Their recommendations will help me make informed decisions.",
          "avatar_url": require("../../assets/reviews/矩形备份 70.jpg")
        },
        {
          "id": 5,
          "name": "Isabella",
          "content": "The inspection was completed on time and the report was delivered promptly. I appreciated the efficient service.",
          "avatar_url": require("../../assets/reviews/矩形备份 250.jpg")
        },
        {
          "id": 6,
          "name": "William",
          "content": "The inspector's keen eye for detail uncovered a few minor concerns that I wouldn't have noticed on my own.",
          "avatar_url": require("../../assets/reviews/矩形备份 58.jpg")
        },
        {
          "id": 7,
          "name": "Olivia",
          "content": "I feel I can trust the inspector's assessment of my home. Their professionalism and integrity are evident.",
          "avatar_url": require("../../assets/reviews/矩形备份 278.jpg")
        },
        {
          "id": 8,
          "name": "Alexander",
          "content": "The inspection gave me a comprehensive understanding of the property's condition. I now have a clear picture of any needed repairs.",
          "avatar_url": require("../../assets/reviews/矩形备份 132.jpg")
        },
        {
          "id": 9,
          "name": "Ava",
          "content": "I'm relieved to have hired an experienced and knowledgeable inspector. Their expertise has been invaluable.",
          "avatar_url": require("../../assets/reviews/矩形备份 126.jpg")
        },
        {
          "id": 10,
          "name": "Daniel",
          "content": "The inspector's thoroughness and attention to safety standards put my mind at ease about the home's structural integrity.",
          "avatar_url": require("../../assets/reviews/矩形备份 127.jpg")
        },
        {
          "id": 11,
          "name": "Mia",
          "content": "I appreciate the inspector's patience in explaining the findings and recommendations. They took the time to ensure I understood everything.",
          "avatar_url": require("../../assets/reviews/矩形备份 133.jpg")
        },
        {
          "id": 12,
          "name": "Michael",
          "content": "The inspection report was thorough and easy to understand. It will be an essential tool in my home-buying decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 279.jpg")
        },
        {
          "id": 13,
          "name": "Emily",
          "content": "The inspector's use of advanced technology, such as thermal imaging, provided an extra layer of assurance.",
          "avatar_url": require("../../assets/reviews/矩形备份 59.jpg")
        },
        {
          "id": 14,
          "name": "Benjamin",
          "content": "I'm confident that the inspector's assessment will help me negotiate a fair price for the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 251.jpg")
        },
        {
          "id": 15,
          "name": "Abigail",
          "content": "The inspector's impartiality and objectivity gave me confidence in their findings and recommendations.",
          "avatar_url": require("../../assets/reviews/矩形备份 71.jpg")
        },
        {
          "id": 16,
          "name": "Joshua",
          "content": "I'm grateful for the inspector's dedication to identifying any potential safety hazards or code violations.",
          "avatar_url": require("../../assets/reviews/矩形备份 65.jpg")
        },
        {
          "id": 17,
          "name": "Harper",
          "content": "The inspection process was smooth and efficient, thanks to the inspector's excellent organizational skills.",
          "avatar_url": require("../../assets/reviews/矩形备份 245.jpg")
        },
        {
          "id": 18,
          "name": "Lucas",
          "content": "The inspector's advice on maintenance and upgrades will be invaluable as I settle into my new home.",
          "avatar_url": require("../../assets/reviews/矩形备份 292.jpg")
        },
        {
          "id": 19,
          "name": "Avery",
          "content": "I'm impressed by the inspector's commitment to ongoing education and staying up-to-date with industry best practices.",
          "avatar_url": require("../../assets/reviews/矩形备份 286.jpg")
        },
        {
          "id": 20,
          "name": "Owen",
          "content": "The inspector's meticulous attention to detail has given me a comprehensive understanding of my home's condition.",
          "avatar_url": require("../../assets/reviews/矩形备份 290.jpg")
        },
        {
          "id": 21,
          "name": "Aubrey",
          "content": "I'm confident that the inspector's findings will help me make informed decisions about the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 284.jpg")
        },
        {
          "id": 22,
          "name": "Henry",
          "content": "The inspector's professionalism and expertise have exceeded my expectations. I highly recommend their services.",
          "avatar_url": require("../../assets/reviews/矩形备份 98.jpg")
        },
        {
          "id": 23,
          "name": "Grace",
          "content": "The inspection report provided a clear and comprehensive overview of the home's strengths and weaknesses.",
          "avatar_url": require("../../assets/reviews/矩形备份 73.jpg")
        },
        {
          "id": 24,
          "name": "Oliver",
          "content": "The inspector's friendly and approachable demeanor helped put me at ease throughout the process.",
          "avatar_url": require("../../assets/reviews/矩形备份 253.jpg")
        },
        {
          "id": 25,
          "name": "Victoria",
          "content": "I'm grateful for the inspector's willingness to answer all my questions and address any concerns I had.",
          "avatar_url": require("../../assets/reviews/矩形备份 247.jpg")
        },
        {
          "id": 26,
          "name": "Elijah",
          "content": "The inspection has given me the confidence to move forward with the home purchase, knowing I'm making an informed decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 67.jpg")
        },
        {
          "id": 27,
          "name": "Natalie",
          "content": "The inspector's use of visual aids, such as photos and diagrams, made the report easy to understand and reference.",
          "avatar_url": require("../../assets/reviews/矩形备份 125.jpg")
        },
        {
          "id": 28,
          "name": "Jack",
          "content": "I'm impressed by the inspector's commitment to providing a thorough and impartial assessment of the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 131.jpg")
        },
        {
          "id": 29,
          "name": "Elizabeth",
          "content": "The inspection report will be an invaluable tool as I plan for any necessary repairs or renovations.",
          "avatar_url": require("../../assets/reviews/矩形备份 119.jpg")
        },
        {
          "id": 30,
          "name": "Matthew",
          "content": "The inspector's expertise and attention to detail have given me a clear understanding of the home's condition.",
          "avatar_url": require("../../assets/reviews/矩形备份 118.jpg")
        },
        {
          "id": 31,
          "name": "Chloe",
          "content": "I'm confident in the inspector's findings and feel well-equipped to make an informed decision about the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 130.jpg")
        },
        {
          "id": 32,
          "name": "Ryan",
          "content": "The inspector's meticulous approach and extensive experience have provided me with great peace of mind.",
          "avatar_url": require("../../assets/reviews/矩形备份 124.jpg")
        },
        {
          "id": 33,
          "name": "Ella",
          "content": "I'm grateful for the inspector's willingness to explain any technical terms or findings in an easily understandable way.",
          "avatar_url": require("../../assets/reviews/矩形备份 66.jpg")
        },
        {
          "id": 34,
          "name": "Jacob",
          "content": "The inspection report has given me valuable insights that will help me negotiate a fair price for the home.",
          "avatar_url": require("../../assets/reviews/矩形备份 246.jpg")
        },
        {
          "id": 35,
          "name": "Evelyn",
          "content": "I'm impressed by the inspector's commitment to safety and their identification of any potential hazards.",
          "avatar_url": require("../../assets/reviews/矩形备份 252.jpg")
        },
        {
          "id": 36,
          "name": "Andrew",
          "content": "The inspector's proactive communication and responsiveness throughout the process have been greatly appreciated.",
          "avatar_url": require("../../assets/reviews/矩形备份 72.jpg")
        },
        {
          "id": 37,
          "name": "Abigail",
          "content": "I'm confident that the inspector's assessment will help me make the best decision for my family and our future home.",
          "avatar_url": require("../../assets/reviews/矩形备份 99.jpg")
        },
        {
          "id": 38,
          "name": "Christopher",
          "content": "The inspector's depth of knowledge and attention to detail have exceeded my expectations in every way.",
          "avatar_url": require("../../assets/reviews/矩形备份 285.jpg")
        },
        {
          "id": 39,
          "name": "Isabella",
          "content": "I'm grateful for the inspector's dedication to providing a comprehensive and unbiased evaluation of the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 291.jpg")
        },
        {
          "id": 40,
          "name": "Joseph",
          "content": "The inspection report has given me a clear roadmap for any necessary repairs or upgrades to the home.",
          "avatar_url": require("../../assets/reviews/矩形备份 89.jpg")
        },
        {
          "id": 41,
          "name": "Hannah",
          "content": "I'm impressed by the inspector's ability to identify potential issues that I would have easily overlooked.",
          "avatar_url": require("../../assets/reviews/矩形备份 295.jpg")
        },
        {
          "id": 42,
          "name": "William",
          "content": "The inspector's professionalism and ethical standards have instilled a high level of trust throughout the process.",
          "avatar_url": require("../../assets/reviews/矩形备份 281.jpg")
        },
        {
          "id": 43,
          "name": "Amelia",
          "content": "I'm confident that the inspector's findings will help me negotiate a fair price and make an informed decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 76.jpg")
        },
        {
          "id": 44,
          "name": "Ethan",
          "content": "The inspection process was streamlined and efficient, thanks to the inspector's excellent organizational skills.",
          "avatar_url": require("../../assets/reviews/矩形备份 256.jpg")
        },
        {
          "id": 45,
          "name": "Isabella",
          "content": "I'm grateful for the inspector's willingness to provide additional clarification or follow-up information as needed.",
          "avatar_url": require("../../assets/reviews/矩形备份 242.jpg")
        },
        {
          "id": 46,
          "name": "Alexander",
          "content": "The inspector's expertise and attention to detail have given me a comprehensive understanding of the home's condition.",
          "avatar_url": require("../../assets/reviews/矩形备份 62.jpg")
        },
        {
          "id": 47,
          "name": "Mia",
          "content": "I'm impressed by the inspector's use of advanced technology to identify potential issues that may have gone unnoticed.",
          "avatar_url": require("../../assets/reviews/矩形备份 108.jpg")
        },
        {
          "id": 48,
          "name": "Daniel",
          "content": "The inspection report has provided me with valuable insights that will help me plan for any necessary home improvements.",
          "avatar_url": require("../../assets/reviews/矩形备份 120.jpg")
        },
        {
          "id": 49,
          "name": "Avery",
          "content": "I'm confident that the inspector's assessment will protect my investment and ensure a sound purchase decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 134.jpg")
        },
        {
          "id": 50,
          "name": "Michael",
          "content": "The inspector's friendly and approachable demeanor made the inspection process a pleasant and informative experience.",
          "avatar_url": require("../../assets/reviews/矩形备份 135.jpg")
        },
        {
          "id": 51,
          "name": "Olivia",
          "content": "I'm grateful for the inspector's dedication to providing an objective and thorough evaluation of the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 121.jpg")
        },
        {
          "id": 52,
          "name": "Benjamin",
          "content": "The inspection report has given me the confidence to move forward with the home purchase, knowing I'm making an informed decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 109.jpg")
        },
        {
          "id": 53,
          "name": "Emma",
          "content": "The inspector's attention to detail and comprehensive approach have provided me with great peace of mind.",
          "avatar_url": require("../../assets/reviews/矩形备份 63.jpg")
        },
        {
          "id": 54,
          "name": "Joshua",
          "content": "I'm confident that the inspector's findings will help me negotiate a fair price and ensure a successful home purchase.",
          "avatar_url": require("../../assets/reviews/矩形备份 243.jpg")
        },
        {
          "id": 55,
          "name": "Harper",
          "content": "The inspection process was smooth and efficient, thanks to the inspector's excellent communication and organizational skills.",
          "avatar_url": require("../../assets/reviews/矩形备份 257.jpg")
        },
        {
          "id": 56,
          "name": "Lucas",
          "content": "I'm grateful for the inspector's willingness to answer all my questions and address any concerns I had throughout the process.",
          "avatar_url": require("../../assets/reviews/矩形备份 77.jpg")
        },
        {
          "id": 57,
          "name": "Liam",
          "content": "The inspector's expertise and attention to detail have given me a clear understanding of the home's strengths and weaknesses.",
          "avatar_url": require("../../assets/reviews/矩形备份 280.jpg")
        },
        {
          "id": 58,
          "name": "Owen",
          "content": "I'm impressed by the inspector's use of visual aids, such as photos and diagrams, which made the report easy to understand and reference.",
          "avatar_url": require("../../assets/reviews/矩形备份 294.jpg")
        },
        {
          "id": 59,
          "name": "Sophia",
          "content": "The inspection report has provided me with invaluable insights that will help me plan for any necessary repairs or renovations.",
          "avatar_url": require("../../assets/reviews/矩形备份 88.jpg")
        },
        {
          "id": 60,
          "name": "Henry",
          "content": "I'm confident that the inspector's impartiality and objectivity have resulted in an accurate and unbiased assessment of the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 282.jpg")
        },
        {
          "id": 61,
          "name": "Ava",
          "content": "The inspector's dedication to identifying any potential safety hazards or code violations has given me great confidence in the home's condition.",
          "avatar_url": require("../../assets/reviews/矩形备份 296.jpg")
        },
        {
          "id": 62,
          "name": "Oliver",
          "content": "I'm grateful for the inspector's willingness to explain any technical terms or findings in a way that was easy for me to understand.",
          "avatar_url": require("../../assets/reviews/矩形备份 269.jpg")
        },
        {
          "id": 63,
          "name": "Aubrey",
          "content": "The inspection process has been a valuable learning experience, thanks to the inspector's expertise and willingness to share their knowledge.",
          "avatar_url": require("../../assets/reviews/矩形备份 49.jpg")
        },
        {
          "id": 64,
          "name": "Elijah",
          "content": "I'm impressed by the inspector's commitment to providing a thorough and comprehensive evaluation of the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 241.jpg")
        },
        {
          "id": 65,
          "name": "Grace",
          "content": "I'm grateful for the inspector's proactive communication and responsiveness throughout the entire process.",
          "avatar_url": require("../../assets/reviews/矩形备份 61.jpg")
        },
        {
          "id": 66,
          "name": "Jack",
          "content": "I'm confident that the inspector's assessment will help me plan for any necessary home improvements and ensure a sound investment.",
          "avatar_url": require("../../assets/reviews/矩形备份 75.jpg")
        },
        {
          "id": 67,
          "name": "Victoria",
          "content": "The inspection process was a positive and informative experience, thanks to the inspector's friendly and approachable demeanor.",
          "avatar_url": require("../../assets/reviews/矩形备份 255.jpg")
        },
        {
          "id": 68,
          "name": "Matthew",
          "content": "The inspection report has provided me with a clear roadmap for any necessary repairs or upgrades to the home.",
          "avatar_url": require("../../assets/reviews/矩形备份 309.jpg")
        },
        {
          "id": 69,
          "name": "Elizabeth",
          "content": "I'm grateful for the inspector's dedication to providing an objective and unbiased evaluation of the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 137.jpg")
        },
        {
          "id": 70,
          "name": "Ryan",
          "content": "I'm confident that the inspector's findings will help me negotiate a fair price and make an informed decision about the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 123.jpg")
        },
        {
          "id": 71,
          "name": "Chloe",
          "content": "The inspection process was streamlined and efficient, thanks to the inspector's excellent organizational skills and communication.",
          "avatar_url": require("../../assets/reviews/矩形备份 122.jpg")
        },
        {
          "id": 72,
          "name": "Andrew",
          "content": "I'm impressed by the inspector's willingness to provide additional clarification or follow-up information as needed.",
          "avatar_url": require("../../assets/reviews/矩形备份 136.jpg")
        },
        {
          "id": 73,
          "name": "Ella",
          "content": "The inspection report has given me valuable insights that will help me plan for any necessary home improvements and protect my investment.",
          "avatar_url": require("../../assets/reviews/矩形备份 308.jpg")
        },
        {
          "id": 74,
          "name": "Christopher",
          "content": "I'm grateful for the inspector's dedication to ongoing education and staying up-to-date with industry best practices.",
          "avatar_url": require("../../assets/reviews/矩形备份 254.jpg")
        },
        {
          "id": 75,
          "name": "Evelyn",
          "content": "The inspector's attention to detail and comprehensive approach have provided me with great peace of mind and confidence in the home's condition.",
          "avatar_url": require("../../assets/reviews/矩形备份 74.jpg")
        },
        {
          "id": 76,
          "name": "Joseph",
          "content": "I'm impressed by the inspector's commitment to identifying any potential safety hazards or code violations, giving me great confidence in the home's condition.",
          "avatar_url": require("../../assets/reviews/矩形备份 60.jpg")
        },
        {
          "id": 77,
          "name": "Abigail",
          "content": "The inspection report has provided me with valuable insights that will help me plan for any necessary home improvements and protect my investment.",
          "avatar_url": require("../../assets/reviews/矩形备份 240.jpg")
        },
        {
          "id": 78,
          "name": "William",
          "content": "I'm impressed by the inspector's willingness to provide additional clarification or follow-up information as needed, ensuring I have a thorough understanding of the report.",
          "avatar_url": require("../../assets/reviews/矩形备份 48.jpg")
        },
        {
          "id": 79,
          "name": "Hannah",
          "content": "I'm extremely satisfied with the thoroughness and professionalism of the inspector's work. Their comprehensive report has been invaluable.",
          "avatar_url": require("../../assets/reviews/矩形备份 268.jpg")
        },
        {
          "id": 80,
          "name": "Ethan",
          "content": "The inspector's keen eye for detail has given me a deep understanding of the home's condition and any necessary improvements.",
          "avatar_url": require("../../assets/reviews/矩形备份 297.jpg")
        },
        {
          "id": 81,
          "name": "Amelia",
          "content": "I'm grateful for the inspector's willingness to address all of my concerns and answer my questions in a patient and thorough manner.",
          "avatar_url": require("../../assets/reviews/矩形备份 283.jpg")
        },
        {
          "id": 82,
          "name": "Alexander",
          "content": "The inspection process was a seamless and efficient experience, thanks to the inspector's excellent communication and organization.",
          "avatar_url": require("../../assets/reviews/矩形备份 227.jpg")
        },
        {
          "id": 83,
          "name": "Isabella",
          "content": "I'm confident that the inspector's impartial assessment will help me make a well-informed decision about this property.",
          "avatar_url": require("../../assets/reviews/矩形备份 233.jpg")
        },
        {
          "id": 84,
          "name": "Daniel",
          "content": "The inspector's expertise and attention to safety have put my mind at ease, knowing my family will be secure in this home.",
          "avatar_url": require("../../assets/reviews/矩形备份 13.jpg")
        },
        {
          "id": 85,
          "name": "Natalie",
          "content": "The inspection report has provided me with a clear roadmap for any necessary repairs or upgrades, which will be invaluable.",
          "avatar_url": require("../../assets/reviews/矩形备份 192.jpg")
        },
        {
          "id": 86,
          "name": "Michael",
          "content": "I'm grateful for the inspector's dedication to ongoing education and staying up-to-date with the latest industry best practices.",
          "avatar_url": require("../../assets/reviews/矩形备份 186.jpg")
        },
        {
          "id": 87,
          "name": "Olivia",
          "content": "The inspector's friendly and approachable demeanor made the entire process a pleasant and informative experience.",
          "avatar_url": require("../../assets/reviews/矩形备份 151.jpg")
        },
        {
          "id": 88,
          "name": "Benjamin",
          "content": "The inspector's commitment to providing a comprehensive and unbiased evaluation has instilled a high level of trust.",
          "avatar_url": require("../../assets/reviews/矩形备份 145.jpg")
        },
        {
          "id": 89,
          "name": "Emma",
          "content": "I'm impressed by the inspector's ability to identify potential issues that I would have easily overlooked as a first-time homebuyer.",
          "avatar_url": require("../../assets/reviews/矩形备份 179.jpg")
        },
        {
          "id": 90,
          "name": "Joshua",
          "content": "The inspection report has given me valuable insights that will help me plan for any necessary home improvements or renovations.",
          "avatar_url": require("../../assets/reviews/矩形备份 178.jpg")
        },
        {
          "id": 91,
          "name": "Harper",
          "content": "I'm grateful for the inspector's willingness to provide additional clarification or follow-up information as needed throughout the process.",
          "avatar_url": require("../../assets/reviews/矩形备份 144.jpg")
        },
        {
          "id": 92,
          "name": "Lucas",
          "content": "The inspector's depth of knowledge and attention to detail have exceeded my expectations, leaving me with a clear understanding of the home's condition.",
          "avatar_url": require("../../assets/reviews/矩形备份 150.jpg")
        },
        {
          "id": 93,
          "name": "Jacob",
          "content": "I'm confident that the inspector's assessment will protect my investment and help me make the best decision for my family and our future home.",
          "avatar_url": require("../../assets/reviews/矩形备份 187.jpg")
        },
        {
          "id": 94,
          "name": "Owen",
          "content": "The inspection process was streamlined and efficient, thanks to the inspector's excellent organizational skills and proactive communication.",
          "avatar_url": require("../../assets/reviews/矩形备份 193.jpg")
        },
        {
          "id": 95,
          "name": "Mia",
          "content": "The inspection report has provided me with the insights and peace of mind I needed to move forward with the home purchase, knowing I'm making an informed decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 12.jpg")
        },
        {
          "id": 96,
          "name": "Henry",
          "content": "I'm grateful for the inspector's willingness to explain any technical terms or findings in a way that was easy for me to understand, ensuring I had a comprehensive understanding.",
          "avatar_url": require("../../assets/reviews/矩形备份 232.jpg")
        },
        {
          "id": 97,
          "name": "Ava",
          "content": "The inspector's expertise and attention to detail have given me a clear roadmap for any necessary repairs or upgrades, which will be invaluable as I settle into my new home.",
          "avatar_url": require("../../assets/reviews/矩形备份 226.jpg")
        },
        {
          "id": 98,
          "name": "Oliver",
          "content": "I'm confident that the inspector's impartiality and objectivity have resulted in an accurate and unbiased assessment of the property, giving me the confidence I need to make a sound investment.",
          "avatar_url": require("../../assets/reviews/矩形备份 230.jpg")
        },
        {
          "id": 99,
          "name": "Avery",
          "content": "The inspection process has been a valuable learning experience, thanks to the inspector's willingness to share their knowledge and expertise throughout the process.",
          "avatar_url": require("../../assets/reviews/矩形备份 10.jpg")
        },
        {
          "id": 100,
          "name": "Emily",
          "content": "I'm impressed by the inspector's commitment to providing a thorough and comprehensive evaluation of the property, leaving no stone unturned.",
          "avatar_url": require("../../assets/reviews/矩形备份 224.jpg")
        },
        {
          "id": 101,
          "name": "Jacob",
          "content": "The inspection report has given me the confidence to move forward with the home purchase, knowing I'm making an informed decision that will protect my investment.",
          "avatar_url": require("../../assets/reviews/矩形备份 218.jpg")
        },
        {
          "id": 102,
          "name": "Olivia",
          "content": "I'm grateful for the inspector's dedication to identifying any potential issues, ensuring I have a clear understanding of the home's strengths and weaknesses.",
          "avatar_url": require("../../assets/reviews/矩形备份 38.jpg")
        },
        {
          "id": 103,
          "name": "William",
          "content": "The inspector's use of visual aids, such as photos and diagrams, has made the inspection report easy to understand and reference, which has been immensely helpful.",
          "avatar_url": require("../../assets/reviews/矩形备份 185.jpg")
        },
        {
          "id": 104,
          "name": "Sophia",
          "content": "I'm confident that the inspector's findings will help me negotiate a fair price and ensure a successful home purchase, giving me peace of mind throughout the process.",
          "avatar_url": require("../../assets/reviews/矩形备份 191.jpg")
        },
        {
          "id": 105,
          "name": "Ethan",
          "content": "The inspector's friendly and approachable demeanor, combined with their expertise and attention to detail, made the entire inspection process a pleasant and informative experience.",
          "avatar_url": require("../../assets/reviews/矩形备份 146.jpg")
        },
        {
          "id": 106,
          "name": "Isabella",
          "content": "I'm impressed by the inspector's commitment to ongoing education and staying up-to-date with the latest industry best practices, ensuring they provide the most comprehensive and accurate assessment.",
          "avatar_url": require("../../assets/reviews/矩形备份 152.jpg")
        },
        {
          "id": 107,
          "name": "Alexander",
          "content": "The inspection report has provided me with invaluable insights that will help me plan for any necessary repairs or renovations, giving me a clear roadmap for the future.",
          "avatar_url": require("../../assets/reviews/矩形备份 153.jpg")
        },
        {
          "id": 108,
          "name": "Mia",
          "content": "I'm extremely satisfied with the thoroughness and professionalism of the inspector's work, and I would highly recommend their services to anyone in need of a comprehensive home evaluation.",
          "avatar_url": require("../../assets/reviews/矩形备份 147.jpg")
        },
        {
          "id": 109,
          "name": "Daniel",
          "content": "The inspection was thorough and professional. I feel confident in the condition of my home.",
          "avatar_url": require("../../assets/reviews/矩形备份 190.jpg")
        },
        {
          "id": 110,
          "name": "Michael",
          "content": "I'm impressed with the level of detail and expertise shown by the inspector. They left no stone unturned.",
          "avatar_url": require("../../assets/reviews/矩形备份 184.jpg")
        },
        {
          "id": 111,
          "name": "Abigail",
          "content": "As a first-time homebuyer, the inspection report provided valuable insight and peace of mind.",
          "avatar_url": require("../../assets/reviews/矩形备份 39.jpg")
        },
        {
          "id": 112,
          "name": "Benjamin",
          "content": "The inspector's communication throughout the process was excellent. They explained everything clearly and answered all my questions.",
          "avatar_url": require("../../assets/reviews/矩形备份 219.jpg")
        },
        {
          "id": 113,
          "name": "Joshua",
          "content": "I'm grateful for the inspector's diligence in identifying potential issues. Their recommendations will help me make informed decisions.",
          "avatar_url": require("../../assets/reviews/矩形备份 225.jpg")
        },
        {
          "id": 114,
          "name": "Lucas",
          "content": "The inspection was completed on time and the report was delivered promptly. I appreciated the efficient service.",
          "avatar_url": require("../../assets/reviews/矩形备份 11.jpg")
        },
        {
          "id": 115,
          "name": "Ava",
          "content": "The inspector's keen eye for detail uncovered a few minor concerns that I wouldn't have noticed on my own.",
          "avatar_url": require("../../assets/reviews/矩形备份 231.jpg")
        },
        {
          "id": 116,
          "name": "Henry",
          "content": "I feel I can trust the inspector's assessment of my home. Their professionalism and integrity are evident.",
          "avatar_url": require("../../assets/reviews/矩形备份 29.jpg")
        },
        {
          "id": 117,
          "name": "Owen",
          "content": "The inspection gave me a comprehensive understanding of the property's condition. I now have a clear picture of any needed repairs.",
          "avatar_url": require("../../assets/reviews/矩形备份 209.jpg")
        },
        {
          "id": 118,
          "name": "Oliver",
          "content": "I'm relieved to have hired an experienced and knowledgeable inspector. Their expertise has been invaluable.",
          "avatar_url": require("../../assets/reviews/矩形备份 235.jpg")
        },
        {
          "id": 119,
          "name": "Elijah",
          "content": "The inspector's thoroughness and attention to safety standards put my mind at ease about the home's structural integrity.",
          "avatar_url": require("../../assets/reviews/矩形备份 15.jpg")
        },
        {
          "id": 120,
          "name": "Jack",
          "content": "I appreciate the inspector's patience in explaining the findings and recommendations. They took the time to ensure I understood everything.",
          "avatar_url": require("../../assets/reviews/矩形备份 221.jpg")
        },
        {
          "id": 121,
          "name": "Matthew",
          "content": "The inspection report was thorough and easy to understand. It will be an essential tool in my home-buying decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 180.jpg")
        },
        {
          "id": 122,
          "name": "Ryan",
          "content": "The inspector's use of advanced technology, such as thermal imaging, provided an extra layer of assurance.",
          "avatar_url": require("../../assets/reviews/矩形备份 194.jpg")
        },
        {
          "id": 123,
          "name": "Andrew",
          "content": "I'm confident that the inspector's assessment will help me negotiate a fair price for the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 143.jpg")
        },
        {
          "id": 124,
          "name": "Christopher",
          "content": "The inspector's impartiality and objectivity gave me confidence in their findings and recommendations.",
          "avatar_url": require("../../assets/reviews/矩形备份 157.jpg")
        },
        {
          "id": 125,
          "name": "Joseph",
          "content": "I'm grateful for the inspector's dedication to identifying any potential safety hazards or code violations.",
          "avatar_url": require("../../assets/reviews/矩形备份 156.jpg")
        },
        {
          "id": 126,
          "name": "Natalie",
          "content": "The inspection process was smooth and efficient, thanks to the inspector's excellent organizational skills.",
          "avatar_url": require("../../assets/reviews/矩形备份 142.jpg")
        },
        {
          "id": 127,
          "name": "Hannah",
          "content": "The inspector's advice on maintenance and upgrades will be invaluable as I settle into my new home.",
          "avatar_url": require("../../assets/reviews/矩形备份 195.jpg")
        },
        {
          "id": 128,
          "name": "Amelia",
          "content": "I'm impressed by the inspector's commitment to ongoing education and staying up-to-date with industry best practices.",
          "avatar_url": require("../../assets/reviews/矩形备份 181.jpg")
        },
        {
          "id": 129,
          "name": "Evelyn",
          "content": "The inspector's meticulous attention to detail has given me a comprehensive understanding of my home's condition.",
          "avatar_url": require("../../assets/reviews/矩形备份 220.jpg")
        },
        {
          "id": 130,
          "name": "Chloe",
          "content": "I'm confident that the inspector's findings will help me make informed decisions about the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 14.jpg")
        },
        {
          "id": 131,
          "name": "Ella",
          "content": "The inspector's professionalism and expertise have exceeded my expectations. I highly recommend their services.",
          "avatar_url": require("../../assets/reviews/矩形备份 234.jpg")
        },
        {
          "id": 132,
          "name": "Aubrey",
          "content": "The inspection report provided a clear and comprehensive overview of the home's strengths and weaknesses.",
          "avatar_url": require("../../assets/reviews/矩形备份 208.jpg")
        },
        {
          "id": 133,
          "name": "Grace",
          "content": "The inspector's friendly and approachable demeanor helped put me at ease throughout the process.",
          "avatar_url": require("../../assets/reviews/矩形备份 28.jpg")
        },
        {
          "id": 134,
          "name": "Victoria",
          "content": "I'm grateful for the inspector's willingness to answer all my questions and address any concerns I had.",
          "avatar_url": require("../../assets/reviews/矩形备份 222.jpg")
        },
        {
          "id": 135,
          "name": "Elizabeth",
          "content": "The inspection has given me the confidence to move forward with the home purchase, knowing I'm making an informed decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 236.jpg")
        },
        {
          "id": 136,
          "name": "Emma",
          "content": "I'm impressed by the inspector's ability to identify potential issues that may have gone unnoticed.",
          "avatar_url": require("../../assets/reviews/矩形备份 16.jpg")
        },
        {
          "id": 137,
          "name": "Harper",
          "content": "The inspection process was a seamless and efficient experience, thanks to the inspector's excellent communication and organization.",
          "avatar_url": require("../../assets/reviews/矩形备份 197.jpg")
        },
        {
          "id": 138,
          "name": "Liam",
          "content": "I'm confident that the inspector's findings will help me negotiate a fair price and ensure a successful home purchase.",
          "avatar_url": require("../../assets/reviews/矩形备份 183.jpg")
        },
        {
          "id": 139,
          "name": "Avery",
          "content": "The inspector's expertise and attention to detail have given me a clear understanding of the home's condition.",
          "avatar_url": require("../../assets/reviews/矩形备份 168.jpg")
        },
        {
          "id": 140,
          "name": "Olivia",
          "content": "I'm confident in the inspector's findings and feel well-equipped to make an informed decision about the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 154.jpg")
        },
        {
          "id": 141,
          "name": "Isabella",
          "content": "The inspector's meticulous approach and extensive experience have provided me with great peace of mind.",
          "avatar_url": require("../../assets/reviews/矩形备份 140.jpg")
        },
        {
          "id": 142,
          "name": "Mia",
          "content": "I'm grateful for the inspector's willingness to explain any technical terms or findings in an easily understandable way.",
          "avatar_url": require("../../assets/reviews/矩形备份 141.jpg")
        },
        {
          "id": 143,
          "name": "Abigail",
          "content": "The inspection report has given me valuable insights that will help me negotiate a fair price for the home.",
          "avatar_url": require("../../assets/reviews/矩形备份 155.jpg")
        },
        {
          "id": 144,
          "name": "Emma",
          "content": "I'm impressed by the inspector's commitment to safety and their identification of any potential hazards.",
          "avatar_url": require("../../assets/reviews/矩形备份 169.jpg")
        },
        {
          "id": 145,
          "name": "Sophia",
          "content": "The inspector's proactive communication and responsiveness throughout the process have been greatly appreciated.",
          "avatar_url": require("../../assets/reviews/矩形备份 182.jpg")
        },
        {
          "id": 146,
          "name": "Ava",
          "content": "I'm confident that the inspector's assessment will help me make the best decision for my family and our future home.",
          "avatar_url": require("../../assets/reviews/矩形备份 196.jpg")
        },
        {
          "id": 147,
          "name": "Amelia",
          "content": "The inspector's depth of knowledge and attention to detail have exceeded my expectations in every way.",
          "avatar_url": require("../../assets/reviews/矩形备份 17.jpg")
        },
        {
          "id": 148,
          "name": "Evelyn",
          "content": "I'm grateful for the inspector's dedication to providing a comprehensive and unbiased evaluation of the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 237.jpg")
        },
        {
          "id": 149,
          "name": "Chloe",
          "content": "The inspection report has given me a clear roadmap for any necessary repairs or upgrades to the home.",
          "avatar_url": require("../../assets/reviews/矩形备份 223.jpg")
        },
        {
          "id": 150,
          "name": "Ella",
          "content": "I'm impressed by the inspector's ability to identify potential issues that I would have easily overlooked.",
          "avatar_url": require("../../assets/reviews/矩形备份 206.jpg")
        },
        {
          "id": 151,
          "name": "Aubrey",
          "content": "The inspector's professionalism and ethical standards have instilled a high level of trust throughout the process.",
          "avatar_url": require("../../assets/reviews/矩形备份 32.jpg")
        },
        {
          "id": 152,
          "name": "Grace",
          "content": "I'm confident that the inspector's findings will help me negotiate a fair price and make an informed decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 212.jpg")
        },
        {
          "id": 153,
          "name": "Victoria",
          "content": "The inspection process was streamlined and efficient, thanks to the inspector's excellent organizational skills.",
          "avatar_url": require("../../assets/reviews/矩形备份 170.jpg")
        },
        {
          "id": 154,
          "name": "Elizabeth",
          "content": "I'm grateful for the inspector's willingness to provide additional clarification or follow-up information as needed.",
          "avatar_url": require("../../assets/reviews/矩形备份 164.jpg")
        },
        {
          "id": 155,
          "name": "Harper",
          "content": "The inspector's expertise and attention to detail have given me a comprehensive understanding of the home's condition.",
          "avatar_url": require("../../assets/reviews/矩形备份 158.jpg")
        },
        {
          "id": 156,
          "name": "Liam",
          "content": "I'm impressed by the inspector's use of advanced technology to identify potential issues that may have gone unnoticed.",
          "avatar_url": require("../../assets/reviews/矩形备份 159.jpg")
        },
        {
          "id": 157,
          "name": "Avery",
          "content": "The inspection report has provided me with valuable insights that will help me plan for any necessary home improvements.",
          "avatar_url": require("../../assets/reviews/矩形备份 165.jpg")
        },
        {
          "id": 158,
          "name": "Jacob",
          "content": "I'm confident that the inspector's assessment will protect my investment and ensure a sound purchase decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 171.jpg")
        },
        {
          "id": 159,
          "name": "Ethan",
          "content": "The inspector's friendly and approachable demeanor made the inspection process a pleasant and informative experience.",
          "avatar_url": require("../../assets/reviews/矩形备份 213.jpg")
        },
        {
          "id": 160,
          "name": "Alexander",
          "content": "I'm grateful for the inspector's dedication to providing an objective and thorough evaluation of the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 33.jpg")
        },
        {
          "id": 161,
          "name": "Daniel",
          "content": "The inspection report has given me the confidence to move forward with the home purchase, knowing I'm making an informed decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 27.jpg")
        },
        {
          "id": 162,
          "name": "Michael",
          "content": "I'm impressed by the inspector's commitment to ongoing education and staying up-to-date with industry best practices.",
          "avatar_url": require("../../assets/reviews/矩形备份 207.jpg")
        },
        {
          "id": 163,
          "name": "Benjamin",
          "content": "The inspector's attention to detail and comprehensive approach have provided me with great peace of mind.",
          "avatar_url": require("../../assets/reviews/矩形备份 31.jpg")
        },
        {
          "id": 164,
          "name": "Joshua",
          "content": "I'm confident that the inspector's findings will help me negotiate a fair price and ensure a successful home purchase.",
          "avatar_url": require("../../assets/reviews/矩形备份 211.jpg")
        },
        {
          "id": 165,
          "name": "Lucas",
          "content": "The inspection process was smooth and efficient, thanks to the inspector's excellent communication and organization.",
          "avatar_url": require("../../assets/reviews/矩形备份 205.jpg")
        },
        {
          "id": 166,
          "name": "Henry",
          "content": "I'm impressed by the inspector's ability to identify potential issues that may have gone unnoticed.",
          "avatar_url": require("../../assets/reviews/矩形备份 19.jpg")
        },
        {
          "id": 167,
          "name": "Owen",
          "content": "The inspection report has provided me with valuable insights that will help me plan for any necessary home improvements.",
          "avatar_url": require("../../assets/reviews/矩形备份 239.jpg")
        },
        {
          "id": 168,
          "name": "Oliver",
          "content": "I'm confident that the inspector's assessment will protect my investment and ensure a sound purchase decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 198.jpg")
        },
        {
          "id": 169,
          "name": "Elijah",
          "content": "The inspector's friendly and approachable demeanor made the inspection process a pleasant and informative experience.",
          "avatar_url": require("../../assets/reviews/矩形备份 167.jpg")
        },
        {
          "id": 170,
          "name": "Jack",
          "content": "I'm grateful for the inspector's dedication to providing an objective and thorough evaluation of the property.",
          "avatar_url": require("../../assets/reviews/矩形备份 173.jpg")
        },
        {
          "id": 171,
          "name": "Matthew",
          "content": "The inspection report has given me the confidence to move forward with the home purchase, knowing I'm making an informed decision.",
          "avatar_url": require("../../assets/reviews/矩形备份 172.jpg")
        },
        {
          "id": 172,
          "name": "Ryan",
          "content": "I'm impressed by the inspector's commitment to ongoing education and staying up-to-date with industry best practices.",
          "avatar_url": require("../../assets/reviews/矩形备份 166.jpg")
        },
        {
          "id": 173,
          "name": "Andrew",
          "content": "The inspector's attention to detail and comprehensive approach have provided me with great peace of mind.",
          "avatar_url": require("../../assets/reviews/矩形备份 199.jpg")
        },
        {
          "id": 174,
          "name": "Christopher",
          "content": "I'm confident that the inspector's findings will help me negotiate a fair price and ensure a successful home purchase.",
          "avatar_url": require("../../assets/reviews/矩形备份 238.jpg")
        },
        {
          "id": 175,
          "name": "Joseph",
          "content": "The inspection process was smooth and efficient, thanks to the inspector's excellent communication and organization.",
          "avatar_url": require("../../assets/reviews/矩形备份 18.jpg")
        },
        {
          "id": 176,
          "name": "Natalie",
          "content": "I'm extremely satisfied with the thoroughness and professionalism of the inspector's work. Their comprehensive report has been invaluable.",
          "avatar_url": require("../../assets/reviews/矩形备份 204.jpg")
        },
        {
          "id": 177,
          "name": "Hannah",
          "content": "The inspector's keen eye for detail has given me a deep understanding of the home's condition and any necessary improvements.",
          "avatar_url": require("../../assets/reviews/矩形备份 210.jpg")
        },
        {
          "id": 178,
          "name": "Emily",
          "content": "I'm grateful for the inspector's willingness to address all of my concerns and answer my questions in a patient and thorough manner.",
          "avatar_url": require("../../assets/reviews/矩形备份 30.jpg")
        },
        {
          "id": 179,
          "name": "Olivia",
          "content": "The inspection process was a seamless and efficient experience, thanks to the inspector's excellent communication and organization.",
          "avatar_url": require("../../assets/reviews/矩形备份 228.jpg")
        },
        {
          "id": 180,
          "name": "William",
          "content": "I'm confident that the inspector's impartial assessment will help me make a well-informed decision about this property.",
          "avatar_url": require("../../assets/reviews/矩形备份 34.jpg")
        },
        {
          "id": 181,
          "name": "Isabella",
          "content": "The inspector's expertise and attention to safety have put my mind at ease, knowing my family will be secure in this home.",
          "avatar_url": require("../../assets/reviews/矩形备份 214.jpg")
        },
        {
          "id": 182,
          "name": "Mia",
          "content": "I'm impressed by the inspector's use of advanced technology to identify potential issues that may have gone unnoticed.",
          "avatar_url": require("../../assets/reviews/矩形备份 200.jpg")
        },
        {
          "id": 183,
          "name": "Abigail",
          "content": "The inspection report has provided me with a clear roadmap for any necessary repairs or upgrades, which will be invaluable.",
          "avatar_url": require("../../assets/reviews/矩形备份 20.jpg")
        },
        {
          "id": 184,
          "name": "Emma",
          "content": "I'm grateful for the inspector's dedication to ongoing education and staying up-to-date with the latest industry best practices.",
          "avatar_url": require("../../assets/reviews/矩形备份 189.jpg")
        },
        {
          "id": 185,
          "name": "Sophia",
          "content": "The inspector's friendly and approachable demeanor made the entire process a pleasant and informative experience.",
          "avatar_url": require("../../assets/reviews/矩形备份 162.jpg")
        },
        {
          "id": 186,
          "name": "Ava",
          "content": "I'm confident that the inspector's findings will help me negotiate a fair price and ensure a successful home purchase.",
          "avatar_url": require("../../assets/reviews/矩形备份 176.jpg")
        },
        {
          "id": 187,
          "name": "Amelia",
          "content": "The inspector's commitment to providing a comprehensive and unbiased evaluation has instilled a high level of trust.",
          "avatar_url": require("../../assets/reviews/矩形备份 177.jpg")
        },
        {
          "id": 188,
          "name": "Evelyn",
          "content": "I'm impressed by the inspector's ability to identify potential issues that I would have easily overlooked as a first-time homebuyer.",
          "avatar_url": require("../../assets/reviews/矩形备份 163.jpg")
        },
        {
          "id": 189,
          "name": "Chloe",
          "content": "The inspection report has given me valuable insights that will help me plan for any necessary home improvements or renovations.",
          "avatar_url": require("../../assets/reviews/矩形备份 188.jpg")
        }
      ],
      data: [],
      show: false
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(val) {
      if (val == undefined) {
        this.list.forEach(item => {
          if (item.id > 8) {
            return
          }
          this.data.push(item)
        });
      } else {
        this.list.forEach(item => {
          if (item.id > (val - 1) * 9 && item.id <= val * 9) {
            this.data.push(item)
          }
        })
        console.log(this.data);
      }
    },

    handleSizeChange(val) {
      this.data = []
      this.tablePage.pageSize = val
      console.log(`每页 ${val} 条`);
      this.getList(val)
    },
    handleCurrentChange(val) {
      this.data = []
      this.tablePage.pageNum = val
      console.log(`当前页: ${val}`);
      this.getList(val)
    },
  }
};
</script>
<style scoped>
/deep/.active {
  background-color: #007562 !important; /* 选中背景颜色 */
  color: #ffffff; /* 选中文字颜色 */
}

/deep/.el-pagination__total{
  display: none !important;
}
/deep/.el-pagination__jump{
  display: none !important;
}
.c-content {
  padding: 0 166px;
  box-sizing: border-box;
}

.c-content .title {
  font-size: 30px;
  margin-bottom: 30px;
  color: #007562;

}

.reviews-img-list {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reviews-img-list li {
  width: 33%;
  height: 304px;
  box-shadow: 1px 6px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px 32px 29px 34px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.avtar-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avtar-box .name {
  width: 100px;
  font-size: 20px;
  color: #007562;
  vertical-align: middle;
  text-align: left;
}

.reviews-desc {
  margin-top: 20px;
  padding: 10px 20px 20px;
  box-sizing: border-box;
  background: #f3f8f8;
  height: 180px;
  font-size: 14px;


}

@font-face {
  font-family: 'MyCustomFont';
  src: url('../../assets/fonts/Avenir\ Next.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font {
  font-family: 'MyCustomFont', sans-serif;
}

.reviews-desc p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-weight: 300;
  font-size: 16px !important;
}

.reviews-desc .reviews-more {
  color: #007562;
  
  text-decoration: underline;
  cursor: pointer;
}

/deep/.el-pagination {
  margin: 50px 0;
}

.s-waterfall-title {
  width: 90%;
  font-size: 30px;
  color: #007562;
  margin-top: 50px;
  margin-bottom: 55px;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}
</style>
