var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headerTop'),_c('breadcrumb',{attrs:{"breadList":_vm.breadList}}),_c('div',{staticClass:"c-content"},[_vm._m(0),_c('ul',{staticClass:"reviews-img-list"},_vm._l((_vm.data),function(item){return _c('li',{key:item.id,staticClass:"reviews-img-list-item"},[_c('div',{staticClass:"avtar-box"},[_c('p',[_c('img',{staticStyle:{"width":"40px","height":"40px","border-radius":"50%","vertical-align":"middle"},attrs:{"src":item.avatar_url,"alt":""}})]),_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('p'),_vm._m(1,true)]),_c('div',{staticClass:"reviews-desc"},[_c('p',[_vm._v(" "+_vm._s(item.content)+" ")])])])}),0)]),_c('el-pagination',{staticStyle:{"text-align":"center"},attrs:{"background":"","current-page":_vm.tablePage.pageNum,"i18n":{
    prev: '',
    next: '',
    total: ''
  },"page-size":_vm.tablePage.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('footerBottom'),_c('commentDialog',{attrs:{"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s-waterfall-title font",staticStyle:{"display":"flex","flex-direction":"row","margin-right":"20px"}},[_c('span',{staticStyle:{"height":"45px","width":"10px","border-radius":"0px 100px 100px 0px","background-color":"#007562","display":"block"}}),_vm._v("You're in Good Company")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("../../assets/home/star.png"),"alt":""}})])
}]

export { render, staticRenderFns }