<template>
  <div>
    <headerTop />
    <breadcrumb :breadList="breadList" />
    <div class="h-title-box">
      <p class="h-title font"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block; "></span>Our
        Core Values That Drive Our Mission</p>
      <p class="h-detail">
        At Samui Home Service, association with GainGood home service, we are inspired by innovation, powered by the communities we serve, and committed to
        achieving excellence before, during and after the inspection. As a national network of highly trained, certified
        and professional home inspectors, we live by our core values and utilize them to drive virtually every aspect of
        our business so that we stay focused on what's most important delivering quality home inspection services to
        you, our clients.
      </p>
      <ul class="h-img-list">
        <li class="h-img-list-item" v-for="item in list" :key="item.id">
          <p><img :src="item.imgSrc" alt="" /></p>
          <p class="title font">{{ item.title }}</p>
          <p class="desc">{{ item.content }}</p>
        </li>
      </ul>
    </div>
    <div class="h-c-img-box">
      <p class="title font"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block; "></span>Our
        Team</p>
      <div class="h-c-detail-box">
        <p class="desc">
          Service remains at the forefront of everything that we do, and that value is
          emphasized through our client-centric approach, support for veterans, and
          community focus. We treat every home as if it were our own and go out of our way
          to ensure our clients are well-informed, safe, and healthy.With every home
          inspection we conduct, we are not just providing an essential service, but
          serving as a compassionate partner, vested in the well-being of our clients.
        </p>
        <p class="videoImg">
          <!-- <iframe width="500" height="300" src="https://www.youtube.com/watch?v=WOjhFNe5-4c&t=193s"
            title="Inspection pouring concrete on the 3rd floor (Post-Tention)" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
          <iframe width="500" height="300" src="https://www.youtube.com/embed/WOjhFNe5-4c?cc_lang_pref=en"
            title="GianGood Service" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </p>
      </div>
    </div>
    <div class="h-certification">
      <div style="display: flex; flex-direction: row; ">
        <img src="../../assets/home/bacc.png" style="height: 100%;" alt="">
        <div class="h-certification-content">
          <div>
            <p class="h-certification-content-detail font"
              style="font-size: 40px;line-height: 50px;margin-bottom: 20px;">Our team consists of:
            </p>
            <p class="h-certification-content-detail">Senior Civil Engineers,</p>
            <p class="h-certification-content-detail">Professional Civil Engineer, </p>
            <p class="h-certification-content-detail">
              Professional environmental Engineer, 
            </p>
            <p class="h-certification-content-detail">
              Professional Electrical Engineers,
            </p>
            <p class="h-certification-content-detail">
              Professional Mechanical Engineers,
            </p>
            <p class="h-certification-content-detail">
              Professional Architect and building inspectors.
            </p>
          </div>
        </div>
      </div>

      <!-- <p class="title font"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block; z-index: 999;"></span>Our
        Certification</p> -->
      <!-- <div class="h-certification-box">
        <p class="h-certification-img">
          <img src="../../assets/index/people.jpg" alt="" />
        </p>
        <div class="h-certification-content">
          <div>
            
            <p class="h-certification-content-detail">Our team consists of:</p>
            <p class="h-certification-content-detail">Senior Civil Engineers,</p>
            <p class="h-certification-content-detail">Professional Civil Engineer, </p>
            <p class="h-certification-content-detail">
              Professional environmental Engineer, 
            </p>
            <p class="h-certification-content-detail">
              Professional Electrical Engineers,
            </p>
            <p class="h-certification-content-detail">
              Professional Mechanical Engineers,
            </p>
            <p class="h-certification-content-detail">
              Professional Architect and building inspectors.
            </p>
          </div>
        </div>
      </div> -->
      <p class="c-title font"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block; "></span>Contact
        us</p>
      <div class="c-contact-box">
        <div class="c-contact-text">
          <div class="title" style="padding-left: 40px;">
            <p class="p1 font" style="color: #007562;">Samui Home Service</p>
            <!-- <p class="p2">gain good Home service</p> -->
          </div>

          <ul class="c-contact-message">
            <li class="c-contact-message-item">
              <p><img src="../../assets/index/icon_phone.png" alt="" /></p>
              <div class="c-contact-message-address">
                <p class="c-contact-message-frist font">Mobile phone</p>
                <p class="c-contact-message-second">+66 0935748046</p>
              </div>
            </li>
            <li class="c-contact-message-item">
              <p><img src="../../assets/index/icon_email.png" alt="" /></p>
              <div class="c-contact-message-address">
                <p class="c-contact-message-frist font">Email</p>
                <p class="c-contact-message-second">steven@samuihomeservice.com</p>
              </div>
            </li>

            <li class="c-contact-message-item">
              <p><img src="../../assets/index/icon_facebook.png" alt="" /></p>
              <div class="c-contact-message-address">
                <p class="c-contact-message-frist font">Facebook</p>
                <p class="c-contact-message-second">@SamuiHomeService</p>
              </div>
            </li>
            <li class="c-contact-message-item-boom">
              <p><img src="../../assets/index/icon_address.png" alt="" /></p>
              <div class="c-contact-message-address">
                <p class="c-contact-message-frist font">Address</p>
                <p class="c-contact-message-second">Samui Town Center, 108/13 Moo 1, Bophut, Samui, Amphoe Koh SaMui,
                  Surat Thani, Thailand 84320.</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="c-contact-map">
          <!-- <img class="c-contact-map-img" src="../../assets/index/map.jpg" alt=""> -->
          <GmapMap :center="center" :zoom="20" map-type-id="terrain" style="width: 100%; height: 100%">
            <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true"
              :draggable="true" @click="center = m.position" />
          </GmapMap>
        </div>
      </div>
    </div>
    <footerBottom />
  </div>
</template>
<script>
import headerTop from "../../components/header.vue";
import breadcrumb from "../../components/breadcrumb.vue";
import footerBottom from "../../components/footer.vue";
export default {
  components: {
    headerTop,
    breadcrumb,
    footerBottom
  },
  data() {
    return {

      center: { lat: 9.55225733782695, lng: 100.03454199098668, },
      markers: [
        { position: { lat: 9.55225733782695, lng: 100.03454199098668 } }
      ],
      list: [
        {
          id: "1",
          imgSrc: require("../../assets/index/1.png"),
          title: "Integrity",
          content: "Do what is right, not what is expedient.",
        },
        {
          id: "2",
          imgSrc: require("../../assets/index/2.png"),
          title: "Compassion",
          content: "Take joy in helping others.",
        },
        {
          id: "3",
          imgSrc: require("../../assets/index/3.png"),
          title: "Entrepreneurship",
          content: "Own,Do,Learn,Own,Do,Learn Own,Do,Learn,Own,Do…",
        },
        {
          id: "4",
          imgSrc: require("../../assets/index/4.png"),
          title: "Innovation",
          content: "Challenge norms,think outside the box.",
        },
        {
          id: "5",
          imgSrc: require("../../assets/index/5.png"),
          title: "Accountability",
          content: "Others depend on you;own youractions and their outcomes.",
        },
        {
          id: "6",
          imgSrc: require("../../assets/index/6.png"),
          title: "Gratitude",
          content: "Appreciate the journey and those who support it.",
        },
        {
          id: "7",
          imgSrc: require("../../assets/index/7.png"),
          title: "Celebration",
          content: "Recognize achievements and mil estones of individuals and teams.",
        },
      ],
      breadList: ["Home", "Who We Are"]
    };
  },
  mounted() {
    this.map.setZoom(12)
  },
  methods: {

  }
};
</script>
<style scoped>
@font-face {
  font-family: 'MyCustomFont';
  src: url('../../assets/fonts/Avenir\ Next.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font {
  font-family: 'MyCustomFont', sans-serif;
}

.h-title-box {
  padding: 30px 166px 30px;
  padding-bottom: 70px;
  box-sizing: border-box;
}

.h-title {
  font-size: 30px;
  color: #007562;
  text-align: center;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.h-detail {
  font-size: 18px;
  color: #333333;
  line-height: 40px;
  /* text-align: center; */
  margin-top: 30px;
  margin-bottom: 30px;
  /* font-weight: 700 ; */
  /* padding: 0 200px;
  box-sizing: border-box; */
}

.h-img-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.h-img-list-item {
  width: 23%;
  height: 300px;
  background: #e9f3f2;
  padding: 28px 40px 40px 30px;
  box-sizing: border-box;
  margin-bottom: 30px;
  border-radius: 10px;
  margin-right: 20px;
}

.h-img-list-item .title {
  font-size: 18px;
  color: #007562;
}

.h-img-list-item .desc {
  font-size: 18px;
  margin-top: 18px;
  line-height: 40px;

}

.h-c-img-box {
  line-height: 50px;
  height: 500px;
  background: #f5f5f5;
  padding: 30px 166px 30px;
  box-sizing: border-box;
}

.h-c-img-box .title {
  font-size: 30px;
  color: #007562;
  text-align: center;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.h-c-img-box .desc {
  width: 45%;
  font-size: 18px;
  /* font-weight: 300; */
  color: #333333;
}

.h-c-detail-box {
  display: flex;
  margin-top: 30px;

}

.h-c-detail-box {
  line-height: 40px;
  color: #333333;
}

.videoImg {
  margin-left: 86px;
  width: 45%;
  height: 454px;
}

.videoImg img {
  width: 100%;
  height: 100%;
}

.h-certification {
  /* padding: 50px 166px 50px; */
  box-sizing: border-box;

}

.h-certification .title {
  font-size: 30px;
  text-align: center;
  color: #007562;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.h-certification-box {
  display: flex;
}

.h-certification-img {
  width: 42%;
  height: 440px;
}

.h-certification-img img {
  width: 100%;
  height: 100%;
}

.h-certification img {
  width: 50%;
}

.h-certification-content {
  width: 50%;
  color: #333333;
  background-color: #007562;
  display: flex;
  justify-content: left;
  align-items: center;
  /* font-weight: 300; */
}

.h-certification-content-detail {
  font-size: 18px;
  color: white;
  line-height: 40px;
}

.h-certification-content .morebtn {
  margin-top: 40px;
  width: 105px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #007562;
  color: #007562;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
}

.c-title {
  padding: 30px 166px 30px;
  margin-top: 20px;
  font-size: 30px;
  color: #007562;
  text-align: center;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.c-contact-box {
  padding: 30px 166px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px
}

.c-contact-text {
  width: 43%;
  height: 700px;
  background: #f5f5f5;
}

.c-contact-text .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  text-align: center;
  background: #eaf4f2;

}

.c-contact-text .p1 {
  width: 100%;
  font-size: 24px;
  color: #333333;
  text-align: left;
  font-weight: 800;
}

.c-contact-text .p2 {
  width: 100%;
  font-size: 16px;
  color: #666666;
  text-align: left;
}

/deep/.gmnoprint {
  display: none !important;
}

.c-contact-message {
  padding: 0 48px;
  box-sizing: border-box;
  margin: 30px 0;
}

/deep/.ytp-impression-link {
  display: none !important;
}

.c-contact-message-item {
  display: flex;
  border-bottom: 1px solid #979797;
  padding-bottom: 34px;
  box-sizing: border-box;
  margin-bottom: 33px;
}
.c-contact-message-item-boom {
  display: flex;
  padding-bottom: 34px;
  box-sizing: border-box;
  margin-bottom: 33px;
}

.c-contact-message-item-last {
  display: flex;

  padding-bottom: 34px;
  box-sizing: border-box;
  margin-bottom: 33px;
}

.c-contact-message-item-last img {
  width: 40px;
  height: 40px;
}
.c-contact-message-item-boom img{
  width: 40px;
  height: 40px;
}

.c-contact-message-item img {
  width: 40px;
  height: 40px;
}

.c-contact-message-address {
  margin-left: 30px;
}

.c-contact-message-frist {
  font-weight: 800;
  font-size: 18px;
  color: #333333;
}

.c-contact-message-second {
  font-size: 16px;
  color: #666666;
  margin-top: 6px;
}

.c-contact-map {
  width: 55%;
}

.c-contact-map img {
  width: 100%;
  height: 700px;
}
</style>
