<template>
  <div>
    <headerTop />
    <breadcrumb :breadList="breadList"/>
     <div class="c-content">
         <ul class="c-content-list">
             <li class="c-content-item" v-for="item in list" :key="item.id">
                 <p class="c-content-item-img-box"><img class="c-content-item-img" :src="item.imgSrc" alt=""></p>
                 <p class="c-content-item-desc">{{item.content}}</p>
             </li>
         </ul>
     </div>
    <footerBottom/>
  </div>
</template>
<script>
import headerTop from "../../components/header.vue";
import breadcrumb from "../../components/breadcrumb.vue";
import footerBottom from "../../components/footer.vue";
export default {
  components: {
    headerTop,
    breadcrumb,
    footerBottom
  },
  data() {
    return {
      breadList: [ "Home", "Certicication"],
      list: [
        {
          id: "1",
          imgSrc: require("../../assets/index/word.jpg"),
          content: "Engineer License, Juristic Person Type awarded by Council of Engineer, registered number Nor. Tor. 1868/64",
        },
        {
          id: "2",
          imgSrc: require("../../assets/index/word.jpg"),
          content: "Engineer License, Juristic Person Type awarded by Council of Engineer, registered number Nor. Tor. 1868/64",
        },
        {
          id: "3",
          imgSrc: require("../../assets/index/word.jpg"),
          content: "Engineer License, Juristic Person Type awarded by Council of Engineer, registered number Nor. Tor. 1868/64",
        },
        {
          id: "4",
          imgSrc: require("../../assets/index/word.jpg"),
          content: "Engineer License, Juristic Person Type awarded by Council of Engineer, registered number Nor. Tor. 1868/64",
        },
      ],
    };
  },
};
</script>
<style scoped>
.c-content {
    padding: 20px 166px 50px;
    box-sizing: border-box;
}
.c-content-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.c-content-item {
    width: 49.5%;
    height: 665px;
    background: #F5F5F5;
    padding-top: 30px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.c-content-item-img-box {
    text-align: center;
}
.c-content-item-img {
    width: 317px;
    height: 450px;
}
.c-content-item-desc {
    padding: 0 61px;
    box-sizing: border-box;
    margin-top: 40px;
    text-indent: 2em;
    font-size: 16px;
    color: #333333;
}

</style>
