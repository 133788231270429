<template>
  <div class="pagination">
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tablePage.pageNum"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="tablePage.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tablePage.pageTotal"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tablePage:{
        pageNum : 1,
        pageSize: 8,
        pageTotal: 0
      },
        currentPage: 1
    };
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>
<style scoped>
.pagination {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 50px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #007562 !important;
}
</style>
